<template>
	<div class="container">
		<top-header></top-header>
		<div class="cloudDatainfo">
			<!-- 头部导航 -->
			<div class="width-1300">
				<div class="info-img">
					<img src="../../../static/images/bz0.jpg" alt="">
				</div>
				<div class="mineinfo">
					<div class="left">
						<img src="../../../static/images/bz3.jpg" alt="">
						<div class="name">
							<span>矿山名称</span>
							<span>Sumbawa</span>
						</div>
						<h4>矿山坐标</h4>
						<div class="address">
							<p>Esquilache Building Calle Esquilache</p>
							<p>371 - piso 10 San lsidro</p>
							<p>Lima</p>
							<p>Peru</p>
						</div>
					</div>
					<div class="right">
						<div class="title">
							<h4 class="title-h4">矿山信息介绍</h4>
						</div>
						<div class="table">
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">construction</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">construction</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									<ul>
										<li>Copper</li>
										<li>Molybdenum</li>
									</ul>
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
							<div class="tr">
								<div class="td">Stage</div>
								<div class="td">
									construction
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 预期产量 -->
				<div class="production">
					<div class="title">
						<h4 class="title-h4">磨机信息介绍</h4>
					</div>
					<div class="table">
						<div class="tr" v-for="(item,index) in 10" :key="index">
							<div class="td">
								<span>Commodity</span>
							</div>
							<div class="td">
								<span>Units</span>
							</div>
						</div>
					</div>
				</div>
				<div class="stateimg">
					<div class="title">
						<h4 class="title-h4">衬板状态图</h4>
					</div>
					<div class="banner">
						<el-carousel height="100%">
							<el-carousel-item v-for="item in 4" :key="item">
								<img src="../../../static/images/img1.png" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<!-- 3D效果图 -->
				<div class="image">
					<div class="title">
						<h4 class="title-h4">3D RENDERING</h4>
					</div>
					<iframe src="./test_3d.html"></iframe>
				</div>
			</div>
		</div>
		<div class="fanhui" @click="gourl">
			<span>返回我的</span>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				
			}
		},
		created() {
		},
		methods: {
			gourl(){
				this.$router.push('/cloudData');
			}
		}
	}
</script>

<style lang="scss">
	.image {
		margin-bottom: 40px;
		text-align: center;

		iframe {
			margin-top: 20px;
			// border: 0;
			width: 75%;
			height: 500px;
			@media screen  and (min-width:320px) and (max-width:767px){
				width: 100%;
			}
		}
	}

	.reserves {
		margin-bottom: 40px;

		.title {
			text-align: center;
		}

		.table {
			margin-top: 20px;
			border-top: 1px solid #dcdcdc;
			@media screen  and (min-width:320px) and (max-width:767px){
				font-size: 13px;
			}
			.tr {
				border-bottom: 1px solid #dcdcdc;
				@media screen  and (min-width:320px) and (max-width:767px){
					display: -webkit-box;
				}
				&:nth-child(2n + 1) {
					background-color: #F2F3F3;
				}

				.th {
					display: inline-block;
					padding: 10px 0;
					vertical-align: top;
					border-right: 1px solid #fff;
					font-weight: 800;
					text-align: center;
					background-color: #DFDFDF;
					@media screen  and (min-width:320px) and (max-width:767px){
						display: table-cell;
					}
					&:nth-child(1) {
						width: 15%;
					}

					&:nth-child(2),
					&:nth-child(3) {
						width: 23%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 23.6%;
						border-right: 0;
						@media screen  and (min-width:320px) and (max-width:767px){
							width: 22.5%;
						}
					}
				}

				.td {
					display: inline-block;
					padding: 10px 20px;
					width: 36%;
					border-right: 1px solid #dcdcdc;
					box-sizing: border-box;
					@media screen  and (min-width:320px) and (max-width:767px){
						padding: 10px 10px;
						display: table-cell;
					}
					&:nth-child(1) {
						width: 15%;
					}

					&:nth-child(2) {
						text-align: right;
						width: 23%;
					}

					&:nth-child(3) {
						text-align: center;
						width: 23%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 23.6%;
						border-right: 0;
						text-align: right;
						@media screen  and (min-width:320px) and (max-width:767px){
							width: 22.5%;
						}
					}
				}
			}
		}
	}
	.stateimg {
		margin-bottom: 40px;
		.banner{
			margin: 0 auto;
			margin-top: 20px;
			width: 70%;
			height: 450px;
			.el-carousel--horizontal{
				height: 100%;
			}
			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
				height: 200px;
			}
		}
		.title {
			text-align: center;
		}
	}
	.production {
		margin-bottom: 40px;

		.title {
			text-align: center;
		}

		.table {
			margin-top: 20px;
			border-top: 1px solid #dcdcdc;
			
			.tr {
				border-bottom: 1px solid #dcdcdc;
				@media screen and(min-width:320px) and (max-width:767px) {
					font-size: 14px;
				}
				&:nth-child(2n + 1) {
					background-color: #F2F3F3;
				}

				.th {
					display: inline-block;
					padding: 10px 0;
					width: 36%;
					border-right: 1px solid #fff;
					text-align: center;
					background-color: #DFDFDF;
				
					&:nth-child(2) {
						width: 27.8%;
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 27.4%;
						}
					}

					&:last-child {
						border-right: 0;
					}
				}

				.td {
					display: inline-block;
					padding: 10px 20px;
					width: 20%;
					border-left: 1px solid #dcdcdc;
					box-sizing: border-box;
					@media screen and(min-width:320px) and (max-width:767px) {
						padding: 10px;
						width: 35%;
					}
					&:nth-child(2) {
						width: 80%;
						border-right: 1px solid #dcdcdc;
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 65%;
						}
					}
				}
			}
		}
	}

	.characteristic {
		margin-bottom: 40px;

		.title {
			text-align: center;
		}

		.label {
			padding-left: 20px;
			padding-bottom: 15px;
			margin-top: 10px;
			color: #666;
			border-bottom: 1px solid #e7e7e7;

			ul {
				margin: 0 0;
				padding: 0 0;

				li {
					&::marker {
						color: #000;
					}
				}
			}
		}

		.test {
			padding-left: 20px;
			margin-top: 10px;
			padding-bottom: 15px;
			border-bottom: 1px solid #2252AA;
		}
	}

	.title-h4 {
		color: #0D4DBA;
		margin: 0 0;
	}
	.info-img{
		img{
			width: 100%;
		}
	}
	.mineinfo {
		margin-bottom: 40px;

		.left {
			width: 20%;
			margin-right: 3%;
			display: inline-block;
			font-size: 14px;
			img {
				width: 100%;
			}
			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
				margin-right: 0;
			}
			.name {
				margin: 10px 0;

				span {
					&:last-child {
						margin-left: 50px;
						color: #3292F3;
					}
				}
			}

			h4 {
				margin: 0 0;
			}

			.address {
				color: #3292F3;
				margin-top: 10px;
			}
		}

		.right {
			width: 77%;
			display: inline-block;
			vertical-align: top;
			@media screen and(min-width:320px) and(max-width:767px) {
				width: 100%;
			}
			.title {
				text-align: center;
			}

			.table {
				border-top: 1px solid #dcdcdc;
				margin-top: 20px;

				.tr {
					border-bottom: 1px solid #dcdcdc;

					&:nth-child(2n-1) {
						background-color: #F2F3F3;
					}

					.td {
						display: inline-block;
						vertical-align: middle;
						width: 75%;
						border-left: 1px solid #dcdcdc;
						padding: 10px 40px;
						box-sizing: border-box;
						@media screen and(min-width:320px) and(max-width:767px) {
							padding: 10px;
							font-size: 14px;
						}
						&:nth-child(1) {
							width: 24.9%;
							text-align: right;
							border-left: 0;
							font-weight: 800;
						}
					}
				}

				ul {
					margin: 0 0;
					padding: 0 0;
					@media screen and(min-width:320px) and(max-width:767px) {
						margin-left: 20px;
					}
				}
			}
		}
	}

	.top-nav {
		padding: 20px 0;

		.nav-item {
			font-size: 14px;
			width: 170px;
			display: inline-block;
			padding: 8px 0;
			background-color: #EBEBEB;
			text-align: center;
			cursor: pointer;
			transition: all .4s;

			&:hover {
				background-color: #3292F3;
				color: #fff;
				@media screen and(min-width:320px) and (max-width:767px) {
					background-color: #fff;
					color: #000;
				}
			}

			@media screen and(min-width:320px) and (max-width:767px) {
				width: 94%;
				display: block;
				margin: 0 auto;
				padding: 15px 0;
				border-bottom: 1px solid #888;
				background-color: #fff;
			}
		}

		.active {
			background-color: #3292F3;
			color: #fff;

			@media screen and(min-width:320px) and (max-width:767px) {
				border-bottom: 1px solid #3292F3;
			}
		}
	}

	.cloudDatainfo {
		margin-top: 85px;
		min-height: 803px;
		@media screen and(min-width:320px) and (max-width:767px) {
			margin-top: 85px;
		}
	}
	
	.fanhui{
		position: fixed;
		right: 75px;
		bottom: 260px;
		background: linear-gradient(-36deg, #0064C8, #3995F0);
		color: #fff;
		font-size: 14px;
		width: 90px;
		height: 90px;
		cursor: pointer;
		text-align: center;
		line-height: 90px;
		border-radius: 50%;
		z-index: 999;
		@media screen and(min-width:320px) and (max-width:767px) {
			display: none;
		}
	}
</style>
